import cn from "classnames";
import { observer } from "mobx-react-lite";

import type { PropsWithChildren } from "react";

import { ErrorInputLabel } from "./ErrorInputLabel";

import styles from "./BaseInput.module.scss";

type Props = PropsWithChildren<{
    title?: string;
    className?: string;
    error?: string;
    inline?: boolean;
}>;

export const BaseInput = observer(function BaseInput(props: Props) {
    const { children, className, title, error, inline } = props;
    return (
        <div className={cn(styles.input, { [styles.input__inline]: inline }, className)}>
            {title && <span>{title}</span>}
            {children}
            <ErrorInputLabel error={error} />
        </div>
    );
});
